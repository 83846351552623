import React from 'react';
import "./Wcard.css"

const Wcard = ({ thumbnail, name, icon, summary }) => {


    return (
        <div className='wcard' key={Math.random * Math.random}>
            <img src={thumbnail} alt="card thumbnail" />
            <img  className='icon' src={icon} alt="" />
            <h1>{name}</h1>
            <p>{summary}</p>
        </div>
    )
}

export default Wcard