
import React, { useRef, useState } from "react";
import "./Contact.css"
import Banner from '../Banner/Banner';
import Testimonial from "../Testimonial/Testimonial"
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { AiOutlineMail, AiOutlineMobile, AiOutlineLinkedin } from "react-icons/ai";
import Img1 from "../../images/Quote1.png"
import Img2 from "../../images/Quote2.png"
import { useNavigate } from "react-router-dom";



const Contact = () => {

  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    contact: "",
    message: ""
  });

  function handleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    // Replace with the URL of your Google Apps Script web app
    const scriptURL = "https://script.google.com/macros/s/AKfycbzjnhTtJiGjzVHV6EZB6wf9p67Ov-3XXJkf87TpSYwMkcIMgWHwd52_JBWEoMrQV77e/exec";

    // Send the form data to the server using an AJAX request
    fetch(scriptURL, {
      method: "POST",
      body: new FormData(e.target)
    })
      .then(function (response) {
        return response.text();
      })
      .then(function (text) {

        navigate('/success')
      });
  }







  return (
    <div className="contact">
      <Banner para='Fill the form below and we will get in touch with you shortly' title='Ready to' stitle='Build' etitle='Something Great?' />
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="name">
            <input value={formData.fname}
              onChange={handleChange}
              type="text" name='fname' placeholder='First Name' required />

            <input value={formData.lname}
              onChange={handleChange} type="text" name='lname' placeholder='Last Name' required />

          </div>
          <div className="cdetails">

            <input value={formData.email}
              onChange={handleChange} type="email" name='email' placeholder='Email' required />

            <input value={formData.contact}
              onChange={handleChange} type="tel" name='contact' placeholder='Contact No' required />
          </div>
          <div className="message">

            <textarea value={formData.message}
              onChange={handleChange} name="message" rows={5} placeholder='How can we help you?'></textarea>
          </div>
          <input className='sbtn' type="submit" />
        </form>
      </div>

      {/* <div className="testimonials">
        <img className="img1" src={Img1} alt="QUOTES" />
        <img className="img2" src={Img2} alt="QUOTES" />
        <h3>Testimonials</h3>
        <h2>Hear From Some of Our Recent Clients</h2>
        <Swiper
         
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide><Testimonial /></SwiperSlide>
          <SwiperSlide><Testimonial /></SwiperSlide>
          <SwiperSlide><Testimonial /></SwiperSlide>
          <SwiperSlide><Testimonial /></SwiperSlide>

        </Swiper>
      </div> */}

      <div className="reach">
        <h3>Reach Out</h3>
        <h2>Let's Connect</h2>

        <div className="cardscon">
          <div className="card">
            <div><AiOutlineMail /></div>
            <h2>Email</h2>
            <a href="#">contact@webvelocity.in</a>
          </div>
          <div className="card">
            <div><AiOutlineMobile /></div>
            <h2>Phone</h2>
            <a href="#">+91 93216 09211</a>
          </div>
          <div className="card">
            <div><AiOutlineLinkedin /></div>
            <h2>Linkedin</h2>
            <a href="#">Connect</a>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Contact