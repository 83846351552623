export const tl1 = require("./html.png");
export const tl2 = require("./css.png");
export const tl3 = require("./js.png");
export const tl4 = require("./react.png");
export const tl5 = require("./nextjs-icon.png");
export const tl6 = require("./nodejs.png");
export const tl7 = require("./python.png");
export const tl8 = require("./aws.png");
export const tl9 = require("./software.png");
export const tl10 = require("./app-development.png");
export const tl11 = require("./engineering.png");
export const tl12 = require("./migrating.png");
export const tl13 = require("./hs1.gif");
export const tl14 = require("./hs2.gif");
export const tl15 = require("./hs3.gif");
export const tl16 = require("./hs4.gif");
export const img1 = require("./1.png");
export const img2 = require("./2.png");
export const img3 = require("./11.png");
export const img4 = require("./12.png");
export const img5 = require("./13.png");
export const img6 = require("./14.png");
export const img7 = require("./15.png");
export const img8 = require("./16.png");
export const img9 = require("./17.png");
export const img10 = require("./18.png");



