import React, { useState, useEffect } from 'react';
import "./Herosec.css";
import { tl13, tl14, tl15, tl16 } from "../../images/index";
import { Link } from "react-router-dom"


const paragraphs = [
  'Our company excels in web development, creating captivating and responsive websites that enhance online presence, driven by user experience, modern design trends, and tailored solutions to achieve impactful results.',

  'Our company excels in app development, creating innovative mobile applications that seamlessly integrate functionality, aesthetics, and intuitive user experiences, leveraging the latest technologies.',

  'Our company specializes in cloud development, offering scalable and secure solutions that help businesses harness the power of cloud computing for enhanced efficiency, scalability, and innovation.',

  'Our company pioneers intelligent automation and algo trading systems, leveraging advanced technologies to empower clients with precise and efficient strategies, unlocking new opportunities in financial markets.'
];

const title = [
  'Web Development',
  'App Development',
  'Cloud Services',
  'Automation'
];

const images = [
  tl16,
  tl15,
  tl14,
  tl13
];

function Herosec() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % paragraphs.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [index]);

  return (
    <div className='herosec' >
      <div className='leftsec' >
        <h1>Empowering Your Digital Journey
        </h1>
        <p>{paragraphs[index]} - <span>{title[index]}</span> </p>
        <Link to='/contact'>Talk to us</Link>
      </div>
      <div className='rightsec'>
        <img src={images[index]} alt="hero img" />
      </div>
    </div>
  );
}

export default Herosec;
