import React, { useState } from 'react';
import "./Header.css";
import Logo from "../../images/logo.png"
import { Link } from 'react-router-dom';
import { HiMenuAlt2, HiOutlineX } from "react-icons/hi";


const Header = () => {


    const [open, setOpen] = useState(false);


    const scrollToTop = () => {
        window.scrollTo(0, 0);
        setOpen(!open)
    };

    return (
        <header className='header'>

            <div className="logo">
                <Link to='/'><img src={Logo} alt="Webvelocity Logo" /></Link>
            </div>
            <div className={open ? 'nav open' : 'nav close'}>
                <nav>
                    <Link onClick={scrollToTop} to='/'>Home</Link>
                    <Link onClick={scrollToTop} to='/about'>About Us</Link>
                    <Link onClick={scrollToTop} to='/service'>Services</Link>
                    <Link onClick={scrollToTop} to='/work'>Projects</Link>
                    <Link onClick={scrollToTop} to='/technology'>Technologies</Link>


                </nav>
                <div className="hbtn">
                    <Link onClick={scrollToTop} to='/contact'>Talk to Us</Link>
                </div>

                <div className="cross">
                    <HiOutlineX onClick={() => setOpen(!open)} />
                </div>
            </div>

            <div className="menu">
                <HiMenuAlt2 onClick={() => setOpen(!open)} />
            </div>


        </header>
    )
}

export default Header