import React from 'react';
import './Calltoact.css';
import { Link } from 'react-router-dom';


const Calltoact = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div className="callcon">
            <h3>Let’s Build <br /> Your Product Together!</h3>
            <p>Get a free discovery session and consulting to start your project today.</p>
            <Link to='/contact' onClick={scrollToTop} >Let's Talk</Link>
        </div>
    )
}

export default Calltoact