// Tabs.js
import React, { useState } from 'react';
import { FaMobileAlt } from 'react-icons/fa';
import { RiCloudLine, RiComputerLine } from 'react-icons/ri';
import { BiBot } from 'react-icons/bi';
import './Tabs.css';
// import { img3, img4, img5, img6 } from "../../images/index"
import { img7, img8, img9, img10 } from "../../images/index";
import { tl9, tl10, tl11, tl12 } from '../../images/index'
import Tcard from "../Tcard/Tcard";

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import lgZoom from 'lightgallery/plugins/zoom';

const Tab = ({ title, logo, active, onClick }) => (
  <div
    className={`tab ${active ? 'active' : ''}`}
    onClick={onClick}
  >
    <span className='icon'>{logo} </span>
    <span className="tab-title">{title}</span>
  </div>
);

const data = [
  {
    icon: tl9,
    name: 'Web Development',
    des: 'Websites are the face of every business in todays Digital World. At Webvelocity, we design and develop your websites look, UI & UX, and functionality to generate more business inquiries.',
  },

  {
    icon: tl10,
    name: 'App Development',
    des: 'Our team of expert mobile developers in Mumbai designs the best Mobile Apps for Android and custom iOS applications for businesses. At Webvelocity, we develop UI and UX for your android and iOs applications.',
  },
  {
    icon: tl12,
    name: 'Cloud Services',
    des: 'At Webvelocity, our cloud service experts provide scalable and secure solutions, leveraging the power of cloud technologies. We help businesses harness the benefits of cloud computing for their operations.',
  },

  {
    icon: tl11,
    name: 'Automation',
    des: 'We design and implement robust automation frameworks that optimize workflows and deliver measurable results to streamline business processes, reducing manual effort and enhancing efficiency..',
  }

  
]

const tabs = [
  {
    title: 'Web Development',
    content: img7,
    logo: <RiComputerLine />,
  },
  {
    title: 'App Development',
    content: img8,
    logo: <FaMobileAlt />,
  },
  {
    title: 'Cloud Services',
    content: img9,
    logo: <RiCloudLine />,
  },
  {
    title: 'Automation',
    content: img10,
    logo: <BiBot />,
  },
];



const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);


  return (
    <div className='tabscon'>
      <h2 className="title">What We Do</h2>
      <div className="container">
        <div className="left-section">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              title={tab.title}
              logo={tab.logo}
              active={activeTab === index}
              onClick={() => setActiveTab(index)}
            />
          ))}
        </div>

        <div className="right-section">
            <Tcard icon={data[activeTab].icon} name={data[activeTab].name} des={data[activeTab].des} />
        </div>
      </div>
    </div>
  );
};

export default Tabs;
