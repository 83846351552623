import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Banner from './Components/Banner/Banner';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Technologies from './Components/Technologies/Technologies';
import Work from './Components/Work/Work';
import Home from "./Components/Home/Home"
import About from "./Components/About/About"
import Services from "./Components/Servicess/Services"
import Singlepage from './Components/Singlepage/Singlepage';
import Success from './Components/Success';
import Copyright from "./Components/Copyright"

function App() {
  const location = useLocation();

  const isSuccessPage = location.pathname === '/success';

  return (
    <div className="app">
      {!isSuccessPage && <Header />}

      <div className={!isSuccessPage ? "hspace" : ""}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/service" element={<Services />}></Route>
          <Route path="/work" element={<Work />}></Route>
          <Route path="/work/:id" element={<Singlepage />}></Route>
          <Route path="/technology" element={<Technologies />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/success" element={<Success />}></Route>
        </Routes>
      </div>

      {!isSuccessPage && <Footer />}
      {!isSuccessPage && <Copyright />}
    </div>
  );
}

export default App;
