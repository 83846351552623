import React, { useState, useEffect } from 'react'
import axios from "axios";
import "./Work.css";
import Wcard from '../Wcard/Wcard';
import { Link } from 'react-router-dom'

const Work = () => {

    const [data, setData] = useState([]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const url =
        `https://oh63480nj3.execute-api.ap-south-1.amazonaws.com/Test/`;

    useEffect(() => {
        axios
            .get(url)
            .then((response) => {
                setData(response.data);
                console.log(response.data)

            })
            .catch((error) => {
                console.log(error);

            });
    }, []);

    return (
        <div className='workcon'>
            <h2 className="title">OUR PROJECTS</h2>

            <div className="wcardcon">



                {
                    data.map((data) => {
                        return (

                            <Link onClick={scrollToTop} to={`/work/${data.id}`}><Wcard thumbnail={data.thumbnail} name={data.name} icon={data.icon} summary={data.summary} /></Link>

                        )
                    })
                }

            </div>



        </div>
    )
}

export default Work