import React from 'react';
import './About.css'
import { img1, img2 } from "../../images/index"
const About = () => {
  return (
    <div className="about">

      <div className="aboutcon">
        <div className="acon">

          <div>
            <h1>We create outstanding websites and mobile apps to boost your digital presence.</h1>
            <p>Our expertise lies in automation, web development and app development processes, streamlining workflows to accelerate project timelines and enhance efficiency. By leveraging cutting-edge automation tools and techniques, we enable businesses to optimize their development cycles, reduce manual labor, and deliver high-quality websites and applications in a more efficient and cost-effective manner.</p>
          </div>


          <div>
            <img src={img2} alt="" />
          </div>

        </div>


        <div className="acon">

          <div>
            <img src={img1} alt="" />
          </div>

          <div>
            <h1>Our mission is to
              empower brands to
              achieve their goals</h1>
            <p className='p2'>Automation in web development and app development can revolutionize businesses in multiple ways, such as: <br />
              ✅&nbsp;Enhanced efficiency in building digital platforms.<br />
              ✅&nbsp;Precise targeting of marketing efforts.<br />
              ✅&nbsp; Data-driven decision making for measurable outcomes.<br />
              ✅&nbsp;Cost-effective strategies with significant impact.</p>
          </div>



        </div>
      </div>
    </div>
  )
}

export default About