import React from 'react'

const Copyright = () => {
    return (
        <div className="copyright">
            <p> <span>Webvelocity</span> © {new Date().getFullYear()}. All rights reserved </p>
        </div>
    )
}

export default Copyright