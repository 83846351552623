import React from 'react'
import Testimonial from "../Testimonial/Testimonial"
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Calltoact from '../Calltoact/Calltoact';
import Tabs from '../Tabs/Tabs';
import Technology from '../Technology/Technology';
import Img1 from "../../images/Quote1.png"
import Img2 from "../../images/Quote2.png"
import Herosec from "../Herosec/Herosec"
import Work from '../Work/Work';


const Home = () => {
  return (
    <div className="home">
      <Herosec/>
      <Tabs />
      <Work/>

      <Technology />

      {/* <div className="testimonials">
        <img className="img1" src={Img1} alt="QUOTES" />
        <img className="img2" src={Img2} alt="QUOTES" />
        <h3>Testimonials</h3>
        <h2>Hear From Some of Our Recent Clients</h2>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide><Testimonial /></SwiperSlide>
          <SwiperSlide><Testimonial /></SwiperSlide>
          <SwiperSlide><Testimonial /></SwiperSlide>
          <SwiperSlide><Testimonial /></SwiperSlide>

        </Swiper>
      </div> */}

      <Calltoact />

    </div>
  )
}

export default Home