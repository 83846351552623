import React from 'react';
import "./Tcard.css"

const Tcard = ({ icon, des, name }) => {


  return (
    <div className="card">
      <img src={icon} alt="technology logo" />
      <h3>{name}</h3>
      <p>{des}</p>

    </div>
  )
}

export default Tcard