import React from 'react';
import "./Banner.css"

const Banner = ({ para, title, stitle, etitle }) => {
    return (
        <div className="banner">
            <h1>{title}  <span>{stitle}</span> {etitle} </h1>
            <p>{para}</p>
        </div>
    )
}

export default Banner;