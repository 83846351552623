import React from 'react'
import Banner from '../Banner/Banner';
import Tcard from "../Tcard/Tcard";
import Calltoact from '../Calltoact/Calltoact';
import { tl9, tl10, tl11, tl12 } from '../../images/index'

const Services = () => {



  const data = [
    {
      icon: tl9,
      name: 'Web Development',
      des: 'Websites are the face of every business in todays Digital World. At Webvelocity, we design and develop your websites look, UI & UX, and functionality to generate more business inquiries.',
    },

    {
      icon: tl10,
      name: 'App Development',
      des: 'Our team of expert mobile developers in Mumbai designs the best Mobile Apps for Android and custom iOS applications for businesses. At Webvelocity, we develop UI and UX for your android and iOs applications.',
    },

    {
      icon: tl11,
      name: 'Automation',
      des: 'We design and implement robust automation frameworks that optimize workflows and deliver measurable results to streamline business processes, reducing manual effort and enhancing efficiency..',
    },

    {
      icon: tl12,
      name: 'Cloud Services',
      des: 'At Webvelocity, our cloud service experts provide scalable and secure solutions, leveraging the power of cloud technologies. We help businesses harness the benefits of cloud computing for their operations.',
    }
  ]

  return (
    <div className="service">
      <Banner title='Your Digital' stitle='Transformation' etitle='Begins Here' para='Reimagine your technology and create a user experience like never before.' />

      <div className="scardcon">
        {data.map((data) => {
          return <Tcard key={Math.random() * Math.random()} icon={data.icon} name={data.name} des={data.des} />
        })}
      </div>

      <Calltoact />
    </div>
  )
}

export default Services