import React from 'react';
import { tl1, tl2, tl3, tl4, tl5, tl6, tl7, tl8 } from '../../images/index';
import "./Technology.css"

const Technology = () => {
    return (

        <div className="technology">
            <h2 className="title">Technologies We Use</h2>
            <div className="ticon">

                <img src={tl1} alt='TechLogo' />
                <img src={tl2} alt='TechLogo' />
                <img src={tl3} alt='TechLogo' />
                <img src={tl4} alt='TechLogo' />
                <img src={tl5} alt='TechLogo' />
                <img src={tl6} alt='TechLogo' />
                <img src={tl7} alt='TechLogo' />
                <img src={tl8} alt='TechLogo' />
            </div>
        </div>

    )
}

export default Technology