import React from 'react';
import "./Footer.css";
import Darklogo from "../../images/darklogo.png"
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from "react-icons/ai";
import { Link } from 'react-router-dom';

const Footer = () => {


    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <footer className='footer'>
            <div className="info">
                <img src={Darklogo} alt="" />
                <p>Empowering Your Digital Journey
                </p>
            </div>
            <div className="fservice">
                <h2>Our Services</h2>

                <Link onClick={scrollToTop} to="/service" href="#">Web Development</Link>
                <Link onClick={scrollToTop} to="/service" href="#">App Development</Link>
                <Link onClick={scrollToTop} to="/service" href="#">Automation</Link>
                <Link onClick={scrollToTop} to="/service" href="#">Cloud Services</Link>


            </div>

            <div className="fcontact">
                <h2>Contact Us</h2>
                <p>
                    1st Floor, Office No - 116,<br />
                    RR Reality, Near Axis Bank, <br />
                    Lbs Marg, Bhandup (W)
                </p>

                <a href="tel:+9193216 09211 ">+91 93216 09211 </a>
            </div>

            <div className="fsocial">
                <h2>Connect With Us</h2>

                <div className="icons">
                    <a target='_blank' href="https://www.linkedin.com/feed/update/urn:li:activity:7094296868880318466/"><AiFillLinkedin size={30} /></a>
                    <a target='_blank' href="https://www.instagram.com/webvelocityofficial/"><AiFillInstagram size={30} /></a>
                    <a target='_blank' href="https://twitter.com/infowebvelocity"><AiFillTwitterSquare size={30} /></a>
                    <a target='_blank' href="https://www.youtube.com/watch?v=bPc9aBFoZvI"><AiFillYoutube size={30} /></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;  